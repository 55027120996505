<template>
  <div>
    <Breadcrumbs />
    <h1>Hello world</h1>
  </div>
</template>

<script>
// import BaseLayout from "../components/common/BaseLayout.vue";
import Breadcrumbs from "../components/common/Breadcrumbs.vue";
export default {
  components: {
    // BaseLayout,
    Breadcrumbs,
  },
};
</script>

<style>
</style>